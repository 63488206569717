import React from "react";
import "../restaurant-locations.scss";
import { Formik, Form, ErrorMessage } from "formik";
import Button from "../../../../components/button";
import * as Yup from "yup";
import ImageUploader from "./imageUploader";
import GoogleAutoComplete from "../../../../components/google-auto-complete";
import PhoneInput from "react-phone-input-2";
import Loading from "../../../../components/loading";
import { validatePhoneNumber } from "../../../../components/formatAndValidate";

function AddRestaurantLocation({ closePopup, handleAddRestaurantLocation, popuploading }) {
  const initialValues = {
    location: null,
    phoneNumber: "",
    phoneCountryCode: "",
  };
  const validationSchema = Yup.object().shape({
    location: Yup.object().nullable().required("Location is required"),
    phoneNumber: Yup.string()
    .required("Phone Number is required")
    .test(
      "is-valid-phone",
      "Phone number is not valid",
      function (value) {
        const countryCode = this.parent.phoneCountryCode;
        return validatePhoneNumber(value, countryCode);
      }
    ),
  });

  const handleSubmitData = (values) => {
    handleAddRestaurantLocation(values);
  };
  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Add Restaurant Location</h2>
        <i onClick={closePopup} className="bx bx-x popClose"></i>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmitData(values)}
        >
          {({ isSubmitting, isValid, setFieldValue, values }) => {
            return (
              <Form>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="location">Location</label>
                      <GoogleAutoComplete
                        onInputEmpty={() => setFieldValue("location", "")}
                        onSelect={(place, address) =>
                          setFieldValue("location", place)
                        }
                      />
                      <div className="error-text">
                        <ErrorMessage name={`location`} />
                      </div>
                    </div>
                  </div>
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <PhoneInput
                        country={"us"}
                        value={values.phoneNumber}
                        onlyCountries={['us']}
                        disableDropdown={true}
                        onChange={(phone, data) => {
                          setFieldValue("phoneCountryCode", data?.dialCode);
                          setFieldValue("phoneNumber", phone);
                        }}
                      />
                      <div className="error-text">
                        <ErrorMessage name={`phoneNumber`} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexRow">
                  <div className="formCol fullcolWidth100">
                    <div className="form-group imgUploaders">
                      <label style={{ marginBottom: "10px" }}>
                        Add Location Specific Photos
                      </label>
                      <ImageUploader
                        handleImage={(image) => setFieldValue("images", image)}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className="btn"
                >
                  {popuploading && <Loading></Loading>}
                  {isSubmitting ? "Submitting..." : "Create"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddRestaurantLocation;
