import React, { useState, useEffect } from "react";
import Layout from "../../../layouts/dashboard-layout";
import Loading from "../../../components/loading";
import { useDispatch, useSelector } from "react-redux";
import { deleteRestaurantCategoryForAdmin, getRestaurantCategoryForAdmin } from "../../../../store/reducers/restaurantCategory";
import { selectRestaurantCategory } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import { createRestaurantCategoryModalHide, createRestaurantCategoryModalOpen, deleteModalHide, deleteModalOpen, editRestaurantCategoryModalHide, editRestaurantCategoryModalOpen } from "../../../../store/reducers/modals";
import { selectIsCreateRestaurantCategoryModalOpen, selectIsDeleteModalOpen, selectIsEditRestaurantCategoryModalOpen } from "../../../../store/selectors/modalSelectors";
import CreateRestaurantCategory from "./create-restaurant-category";
import DeletePopup from "../../../components/delete-popup";
import EditRestaurantCategory from "./edit-restaurant-category";
import "./restaurant-category.scss";

const RestaurantCategory = () => {
  const dispatch = useDispatch();
  const restaurantCategoryData = useSelector(selectRestaurantCategory);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const isCreatePopupOpen = useSelector(selectIsCreateRestaurantCategoryModalOpen);
  const isEditPopupOpen = useSelector(selectIsEditRestaurantCategoryModalOpen);
  const [restaurantCategory, setRestaurantCategory] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRestaurantCategoryId, setSelectedRestaurantCategoryId] = useState(null);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getRestaurantCategory = async () => {
    await dispatch(getRestaurantCategoryForAdmin({ searchQuery })).then(
      (response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            const sortedRestaurantCategories = [
              ...response?.payload?.restaurantCategories,
            ].sort((a, b) =>
              a.restaurantCategoryName.localeCompare(b.restaurantCategoryName)
            );
            setRestaurantCategory(sortedRestaurantCategories);
            setIsDataLoaded(true);
            return;

          default:
            return;
        }
      }
    );
  };

  useEffect(() => {
    getRestaurantCategory();
    dispatch(createRestaurantCategoryModalHide());
    dispatch(editRestaurantCategoryModalHide());
    dispatch(deleteModalHide());
    return () => {
      dispatch(createRestaurantCategoryModalHide());
      dispatch(editRestaurantCategoryModalHide());
      dispatch(deleteModalHide());
    };
  }, [dispatch, searchQuery]);

  const sortAdmins = (criterion, direction) => {
    if (!Array.isArray(restaurantCategory)) {
      return;
    }
    const sortedAdmins = [...restaurantCategory].sort((a, b) => {
      const aValue = a[criterion] || "";
      const bValue = b[criterion] || "";
      if (direction === "asc") {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    });
    setRestaurantCategory(sortedAdmins);
  };

  useEffect(() => {
    sortAdmins(sortBy, sortDirection);
  }, [sortBy, sortDirection]);

  const handleSort = (criterion) => {
    setSortBy(criterion);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const handleDeleteClick = (restaurantCategoryId) => {
    setSelectedRestaurantCategoryId(restaurantCategoryId);
    dispatch(deleteModalOpen());
  };

  const handleEditPopupOpen = (restaurantCategoryId) => {
    setSelectedRestaurantCategoryId(restaurantCategoryId);
    dispatch(editRestaurantCategoryModalOpen());
  }

  const removeAccess = async (restaurantCategoryId) => {
    dispatch(deleteRestaurantCategoryForAdmin({restaurant_category_id: restaurantCategoryId})).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          getRestaurantCategory();
          return;
  
        default:
          return;
      }
    });
  };

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Restaurant Category</h2>
          <div className="iconSce">
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div>
              <i
                onClick={() => dispatch(createRestaurantCategoryModalOpen())}
                className="bx bx-plus"
              ></i>
            </div>
          </div>
        </div>

        <div style={{ position: "relative" }} className="tableSec">
          {restaurantCategoryData?.isLoading && (
            <div className="tableLoader">
              <div className="spinnerLoad">
                <div style={{position:"absolute", top:"50px"}}>
                  <Loading></Loading>
                </div>                
              </div>
            </div>
          )} 
          {isDataLoaded && (
            <table>
              <thead>
                <tr>
                  <th style={{width:"30%"}} scope="col">
                    Name{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("restaurantCategoryName")}
                    />
                  </th>
                  <th style={{width:"40%"}} scope="col">Description </th>
                  <th style={{width:"10%"}} scope="col"></th>
                  <th style={{textAlign:"right"}} scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {restaurantCategory?.length <= 0 && (
                  <tr style={{ borderBottom: "none" }}>
                    <td colSpan="4">
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "17px",
                          padding: "50px 0 0",
                        }}
                      >
                        No Data Found
                      </div>
                    </td>
                  </tr>
                )}

                {restaurantCategory?.map((restaurantCategory) => (
                  <tr key={restaurantCategory?.restaurantCategoryId}>
                    <td data-label="Restaurant Category Name">
                      {restaurantCategory?.restaurantCategoryName}
                    </td>
                    <td data-label="Description">
                      {(restaurantCategory?.description) ? restaurantCategory?.description : 'N/A'}
                    </td>
                    <td className="textEnd" data-label="Actions">
                        <div className="iconSce">
                          <i onClick={() => handleEditPopupOpen(restaurantCategory?.restaurantCategoryId)} class="bx bxs-pencil"></i>
                        </div>                      
                    </td>
                    <td style={{textAlign:"right"}} className="textEnd" data-label="Actions">
                      <div className="iconSce">
                          <i onClick={() => handleDeleteClick(restaurantCategory?.restaurantCategoryId)} class="bx bxs-trash"></i>                        
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isCreatePopupOpen && (
        <CreateRestaurantCategory
          closePopup={() => dispatch(createRestaurantCategoryModalHide())}
          restaurantCategoryData={restaurantCategoryData}
          getRestaurantCategory={getRestaurantCategory}
        />
      )}
      {isEditPopupOpen && (
        <EditRestaurantCategory
          closePopup={() => dispatch(editRestaurantCategoryModalHide())}
          restaurantCategoryData={restaurantCategoryData}
          getRestaurantCategory={getRestaurantCategory}
          selectedRestaurantCategoryId={selectedRestaurantCategoryId}
        />
      )}
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() => removeAccess(selectedRestaurantCategoryId)}
          popuploading={restaurantCategoryData?.isPopupLoading}
          title="Delete Restaurant Category"
          description="Are you sure you want to Delete the Restaurant Category?"
          buttonName="Yes, Delete!"
        />
      )}
    </Layout>
  );
};

export default RestaurantCategory;
