import React, { useState, useEffect } from "react";
import Loading from "../../../components/loading";
import EditRestaurantLocation from "./component/editRestaurantLocation";
import EditRestaurantProfiles from "./component/editRestaurantProfiles";
import AddRestaurantLocation from "./component/addRestaurantLocation";
import toast from "react-hot-toast";
import DeletePopup from "../../../components/delete-popup";
import { useDispatch, useSelector } from "react-redux";
import { createRestaurantLocation, deleteRestaurantLocation, getRestaurantDetail, updateRestaurantLocation, updateRestaurantProfile } from "../../../../store/reducers/restaurantDetail";
import { selectRestaurantDetailsData, selectRestaurantLocationData } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import { selectIsAddRestaurantLocationModalOpen, selectIsDeleteModalOpen, selectIsEditRestaurantLocationModalOpen, selectIsEditRestaurantProfileModalOpen } from "../../../../store/selectors/modalSelectors";
import { userData } from "../../../../store/selectors/authSelectors";
import { createRestaurantLocationOwner, deleteAdminRestaurantLocation, getRestaurantLocationOwnerDetail, updateRestaurantLocationOwner, updateRestaurantLocationProfile } from "../../../../store/reducers/restaurantLocationDetail";
import { addRestaurantLocationModalHide, addRestaurantLocationModalOpen, deleteModalHide, deleteModalOpen, editRestaurantLocationModalHide, editRestaurantLocationModalOpen, editRestaurantProfileModalHide, editRestaurantProfileModalOpen } from "../../../../store/reducers/modals";
import "./restaurant-locations.scss";
import "react-phone-input-2/lib/style.css";
import { formatPhoneNumber } from "../../../components/formatAndValidate";

const RestaurantLocationsComponent = ({ searchQueryAdmin, filteredDataAdmin, loadingAdmin }) => {
  const dispatch = useDispatch();
  const user = useSelector(userData);
  const userIsRestaurant = user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin;
  const restaurantDetailData = useSelector(selectRestaurantDetailsData);
  const [loading, setLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const isEditProfilePopUp = useSelector(selectIsEditRestaurantProfileModalOpen);
  const isEditLocationPopUp = useSelector(selectIsEditRestaurantLocationModalOpen);
  const isAddLocationPopUp = useSelector(selectIsAddRestaurantLocationModalOpen);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const [editRestaurantLocationData, setEditRestaurantLocationData] = useState();
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
  const restaurantDetailDataAdmin = useSelector(selectRestaurantLocationData);
  const restaurantLocationId = restaurantDetailDataAdmin?.restaurantId;
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  //=================ME-API==================================

  const handleSearch = async (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setLoading(true);
    try {
      const filtered =
        restaurantDetailData?.restaurantDetails?.restaurant?.addresses?.filter(
          (address) =>
            address?.streetAddress.toLowerCase().includes(query) ||
            address?.phoneNumber.toLowerCase().includes(query)
        );
      setTimeout(() => {
        setFilteredData(filtered);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getRestaurantDetailDataFunction = userIsRestaurant
      ? () => dispatch(getRestaurantDetail())
      : () => dispatch(getRestaurantLocationOwnerDetail(restaurantLocationId));
    getRestaurantDetailDataFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
    dispatch(addRestaurantLocationModalHide());
    dispatch(deleteModalHide());
    dispatch(editRestaurantLocationModalHide());
    dispatch(editRestaurantProfileModalHide());
    return () => {
      dispatch(addRestaurantLocationModalHide());
      dispatch(deleteModalHide());
      dispatch(editRestaurantLocationModalHide());
      dispatch(editRestaurantProfileModalHide());
    };
  }, [dispatch, userIsRestaurant, restaurantLocationId]);

  //=====================DELETE-RESTAURANT-API==================

  const handleDeleteClick = (id) => {
  if (userIsRestaurant) {
    setSelectedRestaurantId(id);
  } else {
    setSelectedAddressId(id);
  }
  dispatch(deleteModalOpen());
};

const handleRemoveAccess = async (restaurantId, addressId) => {
  if (userIsRestaurant) {
    if (restaurantId) {
      dispatch(deleteRestaurantLocation(restaurantId)).then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            if (isEditLocationPopUp) {
              dispatch(editRestaurantLocationModalHide());
            }
            dispatch(getRestaurantDetail());
            return;
          default:
            return;
        }
      });
    } else {
      toast.error("Invalid Restaurant Id");
    }
  } else {
    if (addressId) {
      dispatch(deleteAdminRestaurantLocation({ restaurantId, addressId })).then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            if (isEditLocationPopUp) {
              dispatch(editRestaurantLocationModalHide());
            }
            dispatch(getRestaurantLocationOwnerDetail(restaurantLocationId));
            return;
          default:
            return;
        }
      });
    } else {
      toast.error("Invalid Address Id");
    }
  }
};

  //==============================Edit-RESTAURANT-PROFILE-API================

  const handleEditRestaurantProfiles = async (values) => {
    const payload = new FormData();
    if (!userIsRestaurant) {
      payload.append("restaurantId", restaurantLocationId);
    }
    payload.append("restaurantName", values?.restaurantName);
    values.category.forEach((item) => {
      payload.append("restaurantCategoryIds", item.value);
    });
    payload.append("emailAddress", values?.email);
    values?.images?.forEach((image, index) => {
      if (!image.imageFileUrl) {
        payload.append(`restaurantImages`, image);
      }
    });
    const updateRestaurantProfileFunction = userIsRestaurant
      ? () => dispatch(updateRestaurantProfile(payload))
      : () => dispatch(updateRestaurantLocationProfile(payload));
    updateRestaurantProfileFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message ||
              "Restaurant Profile updated successfully"
          );
          dispatch(editRestaurantProfileModalHide());
          userIsRestaurant
            ? dispatch(getRestaurantDetail())
            : dispatch(getRestaurantLocationOwnerDetail(restaurantLocationId));
          return;

        default:
          return;
      }
    });
  };

  //============================create-restaurant-location====================

  const handleAddRestaurantLocation = async (values) => {
    const addressComponents = values?.location?.place?.address_components;
    let city, country, state, streetAddress, zipcode, latitude, longitude;
    streetAddress = values?.location?.address;
    addressComponents?.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
        console.log(component.long_name);
      }
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
      if (component.types.includes("country")) {
        country = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        zipcode = component.long_name;
      }
    });
    latitude = values?.location?.place?.geometry?.location.lat();
    longitude = values?.location?.place?.geometry?.location.lng();
    const payload = new FormData();
    if (!userIsRestaurant) {
      payload.append("restaurantId", restaurantLocationId);
    }
    payload.append("streetAddress", streetAddress);
    payload.append("country", country);
    payload.append("state", state);
    payload.append("city", city);
    payload.append("latitude", latitude);
    payload.append("longitude", longitude);
    payload.append("phoneCountryCode", values?.phoneCountryCode);
    payload.append(
      "phoneNumber",
      values?.phoneNumber?.slice(values?.phoneCountryCode?.length)
    );
    if (values.images) {
      values.images.forEach((image, index) => {
        payload.append(`restaurantImages`, image);
      });
    }
    const createRestaurantLocationFunction = userIsRestaurant
      ? () => dispatch(createRestaurantLocation(payload))
      : () => dispatch(createRestaurantLocationOwner(payload));
    createRestaurantLocationFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message ||
              "Restaurant Location created successfully"
          );
          dispatch(addRestaurantLocationModalHide());
          userIsRestaurant
            ? dispatch(getRestaurantDetail())
            : dispatch(getRestaurantLocationOwnerDetail(restaurantLocationId));
          return;

        default:
          return;
      }
    });
  };

  //=====================EditRestaurantLocation=====================

  const handleEditRestaurantLocation = async (values) => {
    const addressComponents = values?.location?.place?.address_components;
    let city, country, state, streetAddress, zipcode, latitude, longitude;
    streetAddress = values?.location?.address;
    addressComponents?.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
      if (component.types.includes("country")) {
        country = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        zipcode = component.long_name;
      }
    });
    latitude = values?.location?.place?.geometry?.location.lat();
    longitude = values?.location?.place?.geometry?.location.lng();
    const payload = new FormData();
    if (!userIsRestaurant) {
      payload.append("restaurantId", restaurantLocationId);
    }
    payload.append(
      "streetAddress",
      streetAddress || editRestaurantLocationData?.streetAddress
    );
    payload.append("country", country || editRestaurantLocationData?.country);
    payload.append("state", state || editRestaurantLocationData?.state);
    payload.append("city", city || editRestaurantLocationData?.city);
    payload.append(
      "latitude",
      latitude || editRestaurantLocationData?.latitude
    );
    payload.append(
      "longitude",
      longitude || editRestaurantLocationData?.longitude
    );
    payload.append(
      "phoneCountryCode",
      values?.phoneCountryCode || editRestaurantLocationData?.phoneCountryCode
    );
    payload.append(
      "phoneNumber",
      values?.phoneNumber?.slice(values?.phoneCountryCode?.length) ||
        editRestaurantLocationData.phoneNumber
    );
    if (values.images) {
      values.images.forEach((image, index) => {
        if (!image.imageFileUrl) {
          payload.append(`restaurantImages`, image);
        }
      });
    }
    const updateRestaurantLocationFunction = userIsRestaurant
      ? () =>
          dispatch(
            updateRestaurantLocation({
              payload,
              addressId: editRestaurantLocationData?.addressId,
            })
          )
      : () =>
          dispatch(
            updateRestaurantLocationOwner({
              payload,
              addressId: editRestaurantLocationData?.addressId,
            })
          );
    updateRestaurantLocationFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message ||
              "Restaurant Location updated successfully"
          );
          dispatch(editRestaurantLocationModalHide());
          userIsRestaurant
            ? dispatch(getRestaurantDetail())
            : dispatch(getRestaurantLocationOwnerDetail(restaurantLocationId));
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className={userIsRestaurant ? "home-content" : undefined}>
      {userIsRestaurant && (
        <div className="bodyTopsec">
          <div className="topSecFlx">
            <div style={{ width: "100%" }}>
              <h2>Restaurant Locations</h2>
            </div>
          </div>
          <div className="iconSce">
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div>
              <i
                onClick={() => dispatch(addRestaurantLocationModalOpen())}
                className="bx bx-plus"
              ></i>
            </div>
          </div>
        </div>
      )}

      {isDataLoaded && (
        <div className={userIsRestaurant ? "resDetSec resMt50" : "resDetSecAdmin"}>
          <div className="resDetSecColOne">
            <h3>Restaurant Name</h3>
            <p>
              {userIsRestaurant
                ? restaurantDetailData?.restaurantDetails?.restaurant
                    ?.restaurantName
                : restaurantDetailDataAdmin?.restaurantDetails?.restaurant
                    ?.restaurantName}
            </p>
          </div>
          <div className="resDetSecColOne ">
            <h3>Email</h3>
            <p>
              {userIsRestaurant
                ? restaurantDetailData?.restaurantDetails?.emailAddress
                : restaurantDetailDataAdmin?.restaurantDetails?.emailAddress}
            </p>
          </div>
          <div className="resDetSecColOne dFlex resDetSec2">
            <h3 style={{ marginRight: "15px" }}>
              Company <br />
              Wide Photos
            </h3>
            <ul className="resPhotoSec">
              {userIsRestaurant
                ? restaurantDetailData?.restaurantDetails?.restaurant
                    ?.companyWideImages &&
                  restaurantDetailData?.restaurantDetails?.restaurant
                    ?.companyWideImages?.length > 0 &&
                  restaurantDetailData?.restaurantDetails?.restaurant?.companyWideImages?.map(
                    (item, index) => (
                      <li key={index}>
                        <img
                          src={item.imageFileUrl}
                          alt={`Image ${index + 1}`}
                        />
                      </li>
                    )
                  )
                : restaurantDetailDataAdmin?.restaurantDetails?.restaurant
                    ?.companyWideImages &&
                  restaurantDetailDataAdmin?.restaurantDetails?.restaurant
                    ?.companyWideImages?.length > 0 &&
                  restaurantDetailDataAdmin?.restaurantDetails?.restaurant?.companyWideImages?.map(
                    (item, index) => (
                      <li key={index}>
                        <img
                          src={item.imageFileUrl}
                          alt={`Image ${index + 1}`}
                        />
                      </li>
                    )
                  )}
            </ul>
          </div>
          <div className="resDetSecColOne resDetSecLast">
            <a
              className="raBtn"
              onClick={() => dispatch(editRestaurantProfileModalOpen())}
            >
              Edit
            </a>
          </div>
        </div>
      )}

      <div
        style={{ position: "relative" }}
        className={userIsRestaurant ? "tableSec" : "tableSec tabsTableBg"}
      >
        {userIsRestaurant
          ? (restaurantDetailData?.isLoading || ((userIsRestaurant ? loading : loadingAdmin))) && (
              <div className="tableLoader">
                <div className="spinnerLoad">
                  <Loading></Loading>
                </div>
              </div>
            )
          : (restaurantDetailDataAdmin?.isLoading || ((userIsRestaurant ? loading : loadingAdmin))) && (
              <div className="tableLoader">
                <div
                  style={{
                    alignItems: "flex-start",
                    marginTop: "30px",
                    background: "none",
                    width: "100%",
                  }}
                  className="spinnerLoad"
                >
                  <Loading></Loading>
                </div>
              </div>
            )}

        {isDataLoaded && (
          <table>
            <thead>
              <tr>
                <th className="firstCol" scope="col">
                  Location{" "}
                </th>
                <th scope="col">Phone Number </th>
                <th scope="col">Location Specific Photos </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {((userIsRestaurant ? searchQuery : searchQueryAdmin).trim() !== "" ? (userIsRestaurant ? filteredData : filteredDataAdmin) : userIsRestaurant ? restaurantDetailData?.restaurantDetails?.restaurant?.addresses : restaurantDetailDataAdmin?.restaurantDetails?.restaurant?.addresses)?.length > 0 ? (
                ((userIsRestaurant ? searchQuery : searchQueryAdmin).trim() !== "" ? (userIsRestaurant ? filteredData : filteredDataAdmin) : userIsRestaurant ? restaurantDetailData?.restaurantDetails?.restaurant?.addresses : restaurantDetailDataAdmin?.restaurantDetails?.restaurant?.addresses
                )?.map((restaurant, index) => (
                  <tr key={index}>
                    <td data-label="Location">{restaurant?.streetAddress}</td>
                    <td data-label="Phone Number">
                      {restaurant?.phoneNumber
                        ? formatPhoneNumber(
                            restaurant?.phoneCountryCode,
                            restaurant?.phoneNumber
                          )
                        : "N/A"}
                    </td>
                    <td data-label="Location Specific Photos">
                      <ul className="resPhotoSec">
                        {restaurant?.locationSpecificImages &&
                        restaurant?.locationSpecificImages?.length > 0
                          ? restaurant?.locationSpecificImages?.map(
                              (item, index) => (
                                <li key={index}>
                                  <img
                                    src={item.imageFileUrl}
                                    alt={`Image ${index + 1}`}
                                  />
                                </li>
                              )
                            )
                          : "N/A"}
                      </ul>
                    </td>
                    <td className="textEnd" data-label="Actions">
                      <a
                        onClick={() => {
                          dispatch(editRestaurantLocationModalOpen());
                          setEditRestaurantLocationData(restaurant);
                        }}
                        className="raBtn editBtnMob"
                      >
                        Edit
                      </a>
                      <a
                        className={userIsRestaurant ? "dealCancleBtn ml-1" : "dealCancleBtnAdmin ml-1"}
                        onClick={() => handleDeleteClick(restaurant?.addressId)}
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ borderBottom: "none" }}>
                  <td colSpan="4">
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "17px",
                        padding: "50px 0 0",
                      }}
                    >
                      No Data Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {isEditLocationPopUp && (
        <EditRestaurantLocation
          closePopup={() => dispatch(editRestaurantLocationModalHide())}
          handleEditRestaurantLocation={handleEditRestaurantLocation}
          editRestaurantLocationData={editRestaurantLocationData}
          handleRemoveAccess={() => {
            if (selectedRestaurantId) {
              if (userIsRestaurant) {
                handleRemoveAccess(selectedRestaurantId);
              } else {
                handleRemoveAccess(selectedAddressId, restaurantLocationId);
              }
            }
          }}
          popuploading={
            userIsRestaurant
              ? restaurantDetailData?.isPopupLoading
              : restaurantDetailDataAdmin?.isPopupLoading
          }
          isDeletePopup={isDeletePopup}
          setSelectedRestaurantId={setSelectedRestaurantId}
          selectedRestaurantId={selectedRestaurantId}
        />
      )}
      {isEditProfilePopUp && (
        <EditRestaurantProfiles
          closePopup={() => dispatch(editRestaurantProfileModalHide())}
          popuploading={
            userIsRestaurant
              ? restaurantDetailData?.isPopupLoading
              : restaurantDetailDataAdmin?.isPopupLoading
          }
          handleEditRestaurantProfiles={handleEditRestaurantProfiles}
          restaurantName={
            userIsRestaurant
              ? restaurantDetailData?.restaurantDetails?.restaurant
                  ?.restaurantName
              : restaurantDetailDataAdmin?.restaurantDetails?.restaurant
                  ?.restaurantName
          }
          restaurantEmail={
            userIsRestaurant
              ? restaurantDetailData?.restaurantDetails?.emailAddress
              : restaurantDetailDataAdmin?.restaurantDetails?.emailAddress
          }
          restaurantImages={
            userIsRestaurant
              ? restaurantDetailData?.restaurantDetails?.restaurant
                  ?.companyWideImages
              : restaurantDetailDataAdmin?.restaurantDetails?.restaurant
                  ?.companyWideImages
          }
          restaurantCategory={
            userIsRestaurant
              ? restaurantDetailData?.restaurantDetails?.restaurant
                  ?.restaurantCategories
              : restaurantDetailDataAdmin?.restaurantDetails?.restaurant
                  ?.restaurantCategories
          }
          restaurantAccountStatus={
            userIsRestaurant
              ? restaurantDetailData?.restaurantDetails?.accountStatus
              : restaurantDetailDataAdmin?.restaurantDetails?.accountStatus
          }
          restaurantLocationId={restaurantLocationId}
        />
      )}
      {isAddLocationPopUp && (
        <AddRestaurantLocation
          closePopup={() => dispatch(addRestaurantLocationModalHide())}
          handleAddRestaurantLocation={handleAddRestaurantLocation}
          popuploading={
            userIsRestaurant
              ? restaurantDetailData?.isPopupLoading
              : restaurantDetailDataAdmin?.isPopupLoading
          }
        />
      )}
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() => {
            if (userIsRestaurant) {
              handleRemoveAccess(selectedRestaurantId);
            } else {
              handleRemoveAccess(restaurantLocationId, selectedAddressId);
            }
          }}
          popuploading={
            userIsRestaurant
              ? restaurantDetailData?.isDeletePopupLoading
              : restaurantDetailDataAdmin?.isDeletePopupLoading
          }
          title="Delete Location"
          description="Are you sure you want to delete this location?  The location data will be erased and this process is irreversible."
          buttonName="Yes, Delete!"
        />
      )}
    </div>
  );
};

export default RestaurantLocationsComponent;
